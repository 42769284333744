<template>
    <div class="tile" v-on:click="toggle" :class="{open: open}">
        <div class="title" :class="{open: open}">
            {{ localize(tile.title) }}
        </div>
        <div class="text" :class="{open: open}" v-html="md(localize(tile.text))"></div>
        <div class="plus-button-wrapper">
            <img class="plus" :class="{open: open}" src="@/assets/x.svg"/>
            <img class="plus light" :class="{open: open}" src="@/assets/plus_white.svg"/>
        </div>
    </div>
</template>

<script>
    import { EventBus } from '@/event-bus'
    import { ToggleTextTile } from '@/events'

    export default {
        name: 'TextTile',

        props: [
            'tile'
        ],

        data() {
            return {
                open: false
            }
        },

        eventListeners: [
            {
                event: ToggleTextTile,
                handler(event){
                    if(event.component != this){
                        this.open = false
                    }
                }
            }
        ],

        methods: {
            toggle() {
                EventBus.publish(new ToggleTextTile(this))
                if(window.innerWidth < 1600){
                    this.open = !this.open
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    $scrollbarPadding: 20px;

    @mixin openTile {
        background: $timberGreen;
        color:white !important;
    }

    @mixin openPlus {
        transform: rotate(0deg);

        &.dark {
            display: none;
        }

        &.light {
            display: block;
        }
    }

    @mixin openTitle {
        font-family: getFontFamily('defaultText');
        font-size: getFontSize('defaultText', 'desktop');
        color: white;
        text-transform: uppercase;
    }

    @mixin openText {
        display: block;
    }

    .tile {
        background: white;
        height: calc(500px - 120px);
        width: calc(100% - 120px);
        position: relative;
        color: $timberGreen;
        overflow: hidden;
        padding-top: 40px;
        padding-left: 60px;
        padding-right: 60px;
        padding-bottom: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-direction: column;
        hyphens: auto;

        @include breakpoint('tablet') {
            padding-top: 30px;
            padding-left: 40px;
            padding-right: 40px;
            height: calc(500px - 110px);
            width: calc(100% - 80px);
        }
        @include breakpoint('mobile') {
            padding-top: 20px;
            padding-left: 30px;
            padding-right: 30px;
            height: calc(400px - 100px);
            width: calc(100% - 60px);
        }

        &:nth-child(odd) {
            background: $lightGrey;
        }
        
        &.open {
            @include openTile;
        }

        .title {
            font-family: getFontFamily('textHeader');
            font-size: getFontSize('textHeader', 'desktop');
            color: $timberGreen;

            @include breakpoint('tablet') {
                font-size: getFontSize('textHeader', 'tablet');
            }
            @include breakpoint('mobile') {
                font-size: getFontSize('textHeader', 'mobile');
            }

            &.open {
                @include openTitle;
            }
        }

        .text {
            display: none;
            color: white;
            text-align: start;
            overflow-y: scroll;
            padding-top: 20px;

            &.open {
                @include openText;
            }
        }

        .plus-button-wrapper {
            margin-top: 10px;
            padding-right: $scrollbarPadding;
            position: absolute;
            bottom: 50px;
            width: calc(100% - 200px);
            display: flex;
            justify-content: center;

            .plus {
                transform: rotate(45deg);
                width: 20px;
                height: 20px;
                cursor: pointer;

                &.dark {
                    display: block;
                }
                &.light {
                    display: none;
                }

                &.open {
                    @include openPlus;
                }

                &:hover {
                    opacity: 0.75;
                }
            }
        }

        @include breakpointAbove('tablet') {

            &:hover {
                @include openTile;

                .title {
                    @include openTitle;
                }

                .text {
                    @include openText;
                }

                .plus-button-wrapper {
                    .plus {
                        @include openPlus;
                    }
                }
            }
        }
    }
</style>

<style lang="scss">
    .tile {
        .text {
            p {
                color: white;
                font-family: getFontFamily('smallText');
                font-size: getFontSize('smallText', 'desktop');
                font-style: getFontStyle('smallText');

                @include breakpoint('tablet') {
                    font-size: getFontSize('smallText', 'tablet');
                }
                @include breakpoint('mobile') {
                    font-size: getFontSize('smallText', 'mobile');
                }
            }
        }
    }
</style>

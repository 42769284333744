<template>
    <section :id="cmsBlock.anchorTag" class="component-container white">
        <div class="tiles">
            <TextTile v-for="tile in cmsBlock.tiles" :key="tile.id" :tile="tile"/>
        </div>
    </section>
</template>

<script>
    import TextTile from '@/components/PageBuilder/Elements/TextTile'

    export default {
        name: 'TextTiles',

        props: [
            'cmsBlock'
        ],

        components: {
            TextTile
        }
    }
</script>

<style lang="scss" scoped>
.component-container {

    .tiles {
        width: 100%;
        max-width: $container-width;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        @include breakpoint('mobile') {
            grid-template-columns: 1fr;
        }
    }
}
</style>
